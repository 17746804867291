import logo from './logo.svg';
import './App.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { redirect } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";

import {
  createRoutesFromElements,
  createBrowserRouter,
  Route,
  Routes,
} from "react-router-dom";

//window.navigate = redirect

function Home() {
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  window.navigate = navigate

  const handleClickOpen2 = () => {
    console.log('handleClickOpen2')
    return navigate(`customers`)
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="Home">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload!
        </p>
        <Button variant="outlined" onClick={handleClickOpen}>
          Open alert dialog
        </Button>
        <Button variant="outlined" onClick={handleClickOpen2}>
          Open customers
        </Button>
        <Link to={`customers`}>customers</Link>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      </header>
    </div>
  );
}


function MyComponent() {
  let { id } = useParams();
  return (<p>{id}</p>);
}


function Customers() {
  return (
    <div className="Home">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
        Customers
        </p>
      </header>
    </div>
  );
}

function About() {
  return (
    <div className="Home">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
        About
        </p>
      </header>
    </div>
  );
}


function Pop() {
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="Home">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload!
        </p>
        <Button variant="outlined" onClick={handleClickOpen}>
          Open alert dialog
        </Button>
        
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn HAHAHA POPOPOP
        </a>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      </header>
    </div>
  );
}

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/about" element={<About />} />
          <Route path="/pop" element={<Pop />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};


/*
<Routes>
  <Route path='/customers' element={<App />}>
    <Route
      path='create'
      element={(
        <Dialog open>
          <p>
            Edit <code>src/App.js</code> and save to reload!
          </p>
        </Dialog>
      )}
    />
  </Route>
</Routes>
*/

export default App;
